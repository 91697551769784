/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import gsap from "gsap"
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import InertiaPlugin from "gsap/InertiaPlugin";
import MorphSVGPlugin from "gsap/MorphSVGPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";
// import "./typography.css";

if (typeof window !== "undefined") {
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, MorphSVGPlugin, InertiaPlugin);
}