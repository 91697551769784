exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-magazin-js": () => import("./../../../src/templates/magazin.js" /* webpackChunkName: "component---src-templates-magazin-js" */),
  "component---src-templates-map-js": () => import("./../../../src/templates/map.js" /* webpackChunkName: "component---src-templates-map-js" */),
  "component---src-templates-subcategory-js": () => import("./../../../src/templates/subcategory.js" /* webpackChunkName: "component---src-templates-subcategory-js" */),
  "component---src-templates-vendor-js": () => import("./../../../src/templates/vendor.js" /* webpackChunkName: "component---src-templates-vendor-js" */)
}

