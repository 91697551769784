const theme = {
    space: [
      0,
      4,
      8,
      16,
      32,
      64,
      128,
      256,
      512
    ],
    fonts: {
      body: 'Merriweather, serif',
      heading: '"DIN Alternate", sans-serif',
      monospace: 'Menlo, monospace'
    },
    fontSizes: [
      8,
      10,
      14,
      18,
      24,
      36,
      48,
      64,
      72,
      96
    ],
    fontWeights: {
      body: 400,
      heading: 700,
      bold: 700
    },
    lineHeights: {
      body: 1.5,
      heading: 1
    },
    colors: {
      text: '#333',
      black: "#000",
      background: '#fff',
      primary: '#1b75bc',
      secondary: '#30c',
      muted: '#f6f6f6',
      gastroBlue: '#1b75bc',
      gastroRed: '#be1e2d',
      gastroGreen: '#006838',
      gastroLillac: '#92278f',
      gastroYellow: '#f7941d',
    },
    
    styles: {
      root: {
        fontFamily: 'body',
        lineHeight: 'body',
        fontWeight: 'body'
      },
      h1: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 6
      },
      h2: {
        color: 'black',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: [5],
        mt: 0,
        mb: 3,
      },
      h3: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 3,
        mb: 3,
      },
      h4: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 2
      },
      h5: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 1
      },
      h6: {
        color: 'text',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 0
      },
      p: {
        color: 'text',
        fontFamily: 'body',
        fontWeight: 'body',
        lineHeight: 'body',
      },
      a: {
        color: 'primary'
      },
      pre: {
        fontFamily: 'monospace',
        overflowX: 'auto',
        code: {
          color: 'inherit'
        }
      },
      code: {
        fontFamily: 'monospace',
        fontSize: 'inherit'
      },
      table: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: 0
      },
      th: {
        textAlign: 'left',
        borderBottomStyle: 'solid'
      },
      td: {
        textAlign: 'left',
        borderBottomStyle: 'solid'
      },
      img: {
        maxWidth: '100%'
      },
      blockquote: {
        fontSize: 5,
      }
    },
    buttons: {
      primary: {
        // you can reference other values defined in the theme
        color: 'white',
        bg: 'primary',
      },
      secondary: {
        color: 'text',
        bg: 'secondary',
        px: 3,
      },
    }
  }

module.exports = theme